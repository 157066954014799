<template>
  <v-data-table
    :headers="headers"
    :items="itens"
    :items-per-page="15"
    :page="pageNumber"
    class="elevation-1 tabela-modelos"
    locale="pt-BR"
    loading-text="Carregando"
    no-data-text="Nenhum dado encontrado"
    :loading="loading"
    :server-items-length="total"
    @update:options="handleFilter"
    :footer-props="{
      'items-per-page-text': 'Modelos por página:',
      'items-per-page-all-text': 'Todos',
      'show-first-last-page': true,
      'show-current-page': true,
      'page-text': pageText,
      'items-per-page-options': [100, 200, 350, 500],
    }"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <template v-for="(header, index) in headers">
          <th :key="index">
            <slot :name="header.value"> </slot>
          </th>
        </template>
      </tr>
    </template>

    <template v-slot:[`item.usoApp`]="{ item }">
      {{ item.usoApp ? "Sim" : "Não" }}
    </template>

    <template v-slot:[`item.produtos`]="{ item }">
      <div v-for="produto in item.produtos" :key="produto.id">
        {{ produto.nome }}
      </div>
    </template>

    <template v-slot:[`item.categorias`]="{ item }">
      <div v-for="categoria in item.categorias" :key="categoria.id">
        {{ categoria.nome }}
      </div>
    </template>

    <template v-slot:[`item.tags`]="{ item }">
      <div v-for="tag in item.tags" :key="tag.id">
        <v-chip class="ma-1" small>
          {{ tag.nome }}
        </v-chip>
      </div>
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <v-menu offset-y v-if="modeloEditar || modeloEditar || modeloExcluir">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small depressed color="fmq_gray" dark v-bind="attrs" v-on="on">
            Opções <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$emit('duplicar', item)" v-if="modeloEditar && !isTr">
            <v-list-item-title>Duplicar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="gotTo(editarRoute, item.id)" v-if="modeloEditar">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('openPDF', item.id)" v-if="modeloExibir && !isTr">
            <v-list-item-title>Visualizar PDF da Carta</v-list-item-title>
          </v-list-item>
          <v-list-item @click="gotTo(visualizarRoute, item.id)" v-if="modeloExibir">
            <v-list-item-title>Visualizar</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('excluir', item)" v-if="modeloExcluir">
            <v-list-item-title>Excluir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "ModelosTabela",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    itens: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageText: {
      type: String,
      default: "-",
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
    isTr: {
      type: Boolean,
      default: false
    },
    visualizarRoute: {
      type: String,
      default: ''
    },
    editarRoute: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: null,
      page: null,
    },
  }),
  methods: {
    handleFilter(event) {
      (this.formData.orderBy = event.sortBy[0]),
        (this.formData.orderSorted = event.sortDesc[0] ? "DESC" : "ASC"),
        (this.formData.perPage = event.itemsPerPage),
        (this.formData.page = event.page);
      this.$emit("handleFilter", this.formData);
    },
    gotTo(routeName ,id) {
      this.$router.push({ name: routeName, params: { id: id } });
    },
    openPDF(hash) {
      window.open(
        process.env.VUE_APP_API + "donwload/modelos/" + hash,
        "_blank"
      );
    },
  },
  computed: {
    modeloEditar() {
      return this.$store.state.user.data.permissoes.includes(
        "modelo.editar"
      );
    },
    modeloExcluir() {
      return this.$store.state.user.data.permissoes.includes(
        "modelo.excluir"
      );
    },
    modeloExibir() {
      return this.$store.state.user.data.permissoes.includes(
        "modelo.exibir"
      );
    },
  },
};
</script>
<style lang="scss">
.tabela-modelos tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-modelos tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
</style>
